/*=========================================================================================
  File Name: modulePatient.js
  Description: Patient Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './modulePatientState.js'
import mutations from './modulePatientMutations.js'
import actions from './modulePatientActions.js'
import getters from './modulePatientGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

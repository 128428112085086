/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  // patients:[],
  Reservations: [],
  ReservationSessionSearchObj: {
    DateFrom: null,
    DateTo: null,
    PatientID: null,
    MedicalPackageName: null,

    MedicalPackageCategoryID: null,
    MedicalPackageCountryID: null,
    StatusID: 1,
    PaymentStatusID: null,
    IsPrivate: null,
  },
  InvoiceSearchObj: {
    DateFrom: null,
    DateTo: null,
    SeviceTypeID: null,
    PatientID: null,
    DoctorID: null,
    HospitalID: null,
    StatusID: null,
    PaymentStatusID: null,
    IsPrivate:null,
  },
  ProfiteSearchObj: { 
    DateFrom: null,
    DateTo: null,
    SeviceTypeID: null,
    PatientID: null,

    DoctorID: null,
    HospitalID: null,
    StatusID: null,
    PaymentStatusID: null,
    IsPrivate:null,
  },
  SettlementSearchObj: {
    DateFrom: null,
    DateTo: null,
    SeviceTypeID: null,
    PatientID: null,

    DoctorID: null,
    HospitalID: null,
    StatusID: null,
    PaymentStatusID: null,
    IsPrivate:null,
  },

};

/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    patients: [],
    PatientMedicalHistoryAnalysis: [],
    PatientMedicalHistoryReports: [],
    PatientMedicalHistoryScans: [],
    PendingMedicalDocumentations: [],
    PatientReservationSurgeries: [],
    ReservationPackages: [],
    ProfitData: [],
    PatientSearchObj: {
        CountryID: '',
        PatientName: null,
        DoctorAssignedID: '',
        AssignedPatientAgentID: null,
        AppointmentDateFrom: '',
        AppointmentDateTo: '',
        SurgeryDateFrom: '',
        SurgeryDateTo: '',
        HavePendingTask: '',
        HavePendingPayment: '',
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20
    }
}

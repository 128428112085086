<!--  =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div>
      <div class="vx-row">
        <div class="vx-col lg:w-4/4 w-full">
          <vs-button v-if="!$acl.check('accountmanager')&&Editable" type="border" color="success" @click="AddAttachment">{{
              $t("Add")
            }}</vs-button>
          <vx-card class="mb-base">
            <vs-table
              ref="table"
              pagination
              :max-items="5"
              :data="UserModel.PatientMedicalHistoryAnalysis"
            >
              <template slot="thead">
                <vs-th>{{ $t("Analysis Type") }}</vs-th>
                <vs-th> {{ $t("Description") }}</vs-th>

                <vs-th> {{ $t("Attachment") }}</vs-th>
                <vs-th> {{ $t("Action") }}</vs-th>

                <!-- <vs-th>{{ $t("Action") }}</vs-th> -->
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td
                      v-if="tr.AnalysisType"
                      class="product-name font-medium truncate"
                    >
                      {{ tr.AnalysisType.NameEN }}
                    </vs-td>
                    <vs-td
                      v-if="tr.Description"
                      class="product-name font-medium truncate"
                    >
                      {{ tr.Description }}
                    </vs-td>

                    <vs-td>
                      <!-- <a :href="tr.URL" download>Download</a> -->
                      <a
                        :href="baseURL + tr.AttachPath"
                        download
                        target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="m-2 "
                        @click="openConfirm(tr)"
                      />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
    <div class="tab-text">
      <vs-popup :active.sync="documentModel.OpenAnalysisPopup">
        <AttachmentPopup
          class="mt-4"
          :data="UserModel"
          :documentdata="documentModel"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
import AttachmentPopup from "./AttachmentPopup.vue";

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      UserModel: this.data,
      documentModel: {
        OpenAnalysisPopup: false,
        DocumentID: 0, 
      },
      rowDataForDelete:{},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
    };
  },
  computed: {
    Editable()
    {
      return  this.UserModel.MainStatusID!=5&& this.UserModel.MainStatusID!=3;
    }, 
  },
  methods: {
     openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert
      });
    },
     acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully")
      });
    },
     deleteData(data) {
      this.$store.dispatch("ReservationList/DeletePatientMedicalHistoryAnalysis", data.ID).then(res=>{
        if(res.status==200){
          const ItemIndex = this.UserModel.PatientMedicalHistoryAnalysis.findIndex(
        p => p.ID == data.ID
      );
        this.UserModel.PatientMedicalHistoryAnalysis.splice(ItemIndex, 1);
        }
      }).catch(err => {
        console.error(err);
      });
    },
    AddAttachment() {
      debugger;
      this.documentModel.DocumentID = 2;
      debugger;
      this.documentModel.PatientReservationSessionID = this.UserModel.ID;
      debugger;
      if (
        this.UserModel.Patient.PatientMedicalHistory != null ||
        this.UserModel.Patient.PatientMedicalHistory != undefined
      )
        this.documentModel.PatientMedicalHistoryID =
          this.UserModel.Patient.PatientMedicalHistory.ID;
      this.documentModel.PatientID = this.UserModel.PatientID;
      this.documentModel.DoctorID = this.UserModel.DoctorID;
      this.documentModel.OpenAnalysisPopup = true;
      debugger;
    },
  },
  components: {
    AttachmentPopup,
  },
  created() {},
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="p-8">
    <div class="vx-card__title mb-4"></div>

    <div class="vx-col mx-auto self-center d-theme-dark-bg">
      <div class="vx-row text-center">
        <v-select
          v-if="documentModel.DocumentID == 1"
          class="w-full"
          label="Name"
          v-model="documentModel.ScanType"
          :options="ScanTypes"
          :reduce="(ID) => ID"
          placeholder="Scan Type"
        />
      </div>
      <div class="vx-row text-center">
        <v-select
          v-if="documentModel.DocumentID == 2"
          class="w-full"
          label="Name"
          v-model="documentModel.AnalysisType"
          :options="AnalysisTypes"
          :reduce="(ID) => ID"
          placeholder="Analysis Type"
        />
      </div>
      <div class="vx-row text-center">
        <v-select
          v-if="documentModel.DocumentID == 3"
          class="w-full"
          label="Name"
          v-model="documentModel.ReportType"
          :options="ReportTypes"
          :reduce="(ID) => ID"
          placeholder="Report Type"
        />
      </div>
      <div class="vx-row mb-3">
        <vs-input
          :label="$t('Description')"
          v-model="documentModel.Description"
          class="mt-3 w-full"
          :data-vv-as="$t('Description')"
          name="Description"
        />
      </div>
    </div>

    <div class="vx-col mb-3 mx-auto self-center d-theme-dark-bg">
      <div class="vx-row text-center">
        <vs-upload
          class="w-full"
          automatic
          :limit="1"
          :action="baseURL + 'api/Common/UploadFile'"
          :headers="UploadRequestHeader"
          @on-success="successUpload"
        />
      </div>
      <div class="vx-row text-center">
        <vs-button
          class="w-full"
          color="primary"
          v-if="uploaded"
          @click="AddAttachmnet"
          >{{ $t("Procced") }}</vs-button
        >
      </div>
      <div class="vx-row text-center"></div>
    </div>
  </div>
</template>

<script>
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
export default {
  components: {},
  props: {
    documentdata: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      documentModel: this.documentdata,
      baseURL: domain,
      uploaded: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
    };
  },
  computed: {
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    successUpload(event) {
      this.documentModel.AttachPath = event.target.responseText;
      window.showAddSuccess();
      this.uploaded = true;
    },
    AddAttachmnet() {
      debugger;
      var apiName = "";
      if (this.documentModel.DocumentID == 1) {
        apiName = "AddScan";
        this.documentModel.ScanTypeID = this.documentModel.ScanType.ID;
        this.data.PatientMedicalHistoryScans.push(this.documentModel);
      }
      if (this.documentModel.DocumentID == 2) {
        debugger
        apiName = "AddAnalysis";
        this.documentModel.AnalysisTypeID = this.documentModel.AnalysisType.ID;
        debugger
        this.data.PatientMedicalHistoryAnalysis.push(this.documentModel);
      }
      if (this.documentModel.DocumentID == 3) {
        apiName = "AddReport";
        this.documentModel.ReportTypeID = this.documentModel.ReportType.ID;
        this.data.PatientMedicalHistoryReports.push(this.documentModel);
      }

      this.$store
        .dispatch("ReservationList/" + apiName, this.documentModel)
        .then((res) => {
          if (res.status == 200) {
            window.showAddSuccess();

            this.documentModel = {
              OpenScanPopup: false,
              OpenAnalysisPopup: false,
              OpenReportPopup: false,
            };
            this.documentdata = {};
            this.documentdata = this.documentModel;
            this.documentdata.OpenReportPopup = false;
            this.uploaded = false;
          }
        })
        .catch(() => {
          window.showError();
          window.showError(err.response.data.message);
        });
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
    <div  class="ml-8 inline-flex " v-if="!$acl.check('accountmanager')">
      <vs-input
          :label="$t('Comment')"
          v-model="Comment"
          style="width:50rem !important"
          name="Comment"
          v-validate="'required'"
        />
        <vs-button
          type="border"
       style="height:3rem !important"
          class="ml-10 mt-6"
          @click="addComment"
         
          >{{ $t("add Comment") }}</vs-button
        >
        </div>
      <div class="vx-col  w-full mt-5">
        <vx-card class="mb-base">
          <vs-table
            ref="table"
            pagination
            :max-items="5"
            :data="UserModel.PatientReservationSessionComments"
          >
            <template slot="thead">
              <vs-th>{{ $t("Comment") }} </vs-th>
              <vs-th> {{ $t("Person") }}</vs-th>
              <vs-th> {{ $t("Date") }}</vs-th>
              <!-- <vs-th>{{ $t("Action") }}</vs-th> -->
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      {{ tr.Comment }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p v-if="tr.User" class="product-name font-medium truncate">
                      {{tr.User.FullName}}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      {{ tr.CreatedDate | formatDate() }}
                    </p>
                  </vs-td>

                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      UserModel: this.data,
      Comment:""
 
    };
  },
  computed: {},
  methods: {
  addComment() {
  
          const obj = {
            ID: this.ID,
            Comment: this.Comment,
            UserID:this.$store.state.AppActiveUser.uid,
            PatientReservationSessionID:this.$route.params.ID
              
          };
   
           if(this.$route.params.ID==0 ||this.$route.params.ID==undefined){
            this.UserModel.PatientReservationSessionComments.push(obj);

           }
           else{
            this.$store.dispatch("ReservationList/AddPatientReservationSessionComment", obj).then(res=>{
             debugger
             
             this.UserModel.PatientReservationSessionComments.push(res.data.Data);
          
          }).catch(err => {
              console.error(err);
              window.showError(err.response.data.message);
            });
           }
            
       


          
    },
  
  },
  created() {},
    mounted() {
    this.isMounted = true;
  }
};
</script>

/*=========================================================================================
  File Name: moduleReportTypeMutations.js
  Description: ReportType Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.ReportTypes.unshift(item)
  },
  SET_ReportType(state, ReportTypes) {
    state.ReportTypes = ReportTypes
  },
  UPDATE_ReportType(state, ReportType) {
    const ReportTypeIndex = state.ReportTypes.findIndex((p) => p.ID == ReportType.ID)
    Object.assign(state.ReportTypes[ReportTypeIndex], ReportType)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.ReportTypes.findIndex((p) => p.ID == itemId)
    state.ReportTypes.splice(ItemIndex, 1)
},
}

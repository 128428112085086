<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card>
     
        
            <vs-table
              ref="table"
              pagination
              :max-items="5"
              :data="UserModel.PatientReservationSessionDiagnosis"
            >
              <template slot="thead">
                <vs-th>{{ $t("Name") }}</vs-th>
                <vs-th> {{ $t("Date") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td class="product-name font-medium truncate">
                      <p v-if="tr.Diagnosis">
                        {{ tr.Diagnosis.Name }}
                      </p>
                    </vs-td>

                    <vs-td class="product-name font-medium truncate">
                      <p v-if="tr.CreatedDate">
                        {{ tr.CreatedDate }}
                      </p>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
         
     
    </vx-card>

  </div>
</template>

<script>
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";

import vSelect from "vue-select";
// import { domain, mainSetting } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    vSelect,
    Datepicker,
  },
  props: {
    UserModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Status: [],
      FilterdStatus: [],

      // uploadUrl: domain + "API/Course/UploadFile",
      // UploadRequestHeader: mainSetting.UploadRequestHeader,
      // baseURL: domain,
    };
  },
  computed: {
    // PaymentStatus() {
    //   return this.$store.state.PaymentStatusList.PaymentStatus;
    // },
    // PaymentTypes() {
    //   return this.$store.state.PaymentTypeList.PaymentTypes;
    // },
  },
  methods: {
    // GetAllStatuses() {
    //   this.$store
    //     .dispatch("ReservationList/GetAllStatuses")
    //     .then((res) => {
    //       this.Status = res.data.Data;
    //       this.FilterdStatus = res.data.Data;
    //       this.FilterStatus(true);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
    // FilterStatus(isCreate) {
    //   if (isCreate != true) {
    //     this.UserModel.StatusID = null;
    //   }
    //   if (this.UserModel.PaymentStatusID < 3) {
    //     this.FilterdStatus = this.Status.filter((x) => x.ID <= 3);
    //   }

    //   if (this.UserModel.PaymentStatusID == 3) {
    //     this.FilterdStatus = this.Status.filter((x) => x.ID == 3);
    //   }
    // },
    // successUpload(event) {
    //   this.UserModel.ProfileImagePath = event.target.responseText;
    //   window.showAddSuccess();
    // },
  },
  created() {
    this.UserModel;
    debugger
    // if (!moduleReservation.isRegistered) {
    //   this.$store.registerModule("ReservationList", moduleReservation);
    //   moduleReservation.isRegistered = true;
    // }
    // this.GetAllStatuses();

    // this.FilterStatus(true);
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
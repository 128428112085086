/*=========================================================================================
  File Name: moduleScanTypeMutations.js
  Description: ScanType Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.ScanTypes.unshift(item)
  },
  SET_ScanType(state, ScanTypes) {
    state.ScanTypes = ScanTypes
  },
  UPDATE_ScanType(state, ScanType) {
    const ScanTypeIndex = state.ScanTypes.findIndex((p) => p.ID == ScanType.ID)
    Object.assign(state.ScanTypes[ScanTypeIndex], ScanType)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.ScanTypes.findIndex((p) => p.ID == itemId)
    state.ScanTypes.splice(ItemIndex, 1)
},
}

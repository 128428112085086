/*=========================================================================================
  File Name: moduleDoctorMutations.js
  Description: Doctor Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_ITEM(state, item) {
        state.doctors.unshift(item)
    },
    SET_Doctor(state, doctors) {
        state.doctors = doctors
    },
    SET_SearchDoctorPaging(state, obj) {
        debugger
        if (obj.PageNumber == 1) {
            state.doctors = obj.doctors
        } else {
            if (obj.doctors.length > 0) {
                obj.doctors.forEach(element => {
                    state.doctors.push(element)
                });
            }
        }

    },
    SET_SearchDoctor(state, doctors) {
        state.searchDoctors = doctors

    },
    SET_languages(state, languages) {
        state.languages = languages
    },
    SET_languages(state, languages) {
        state.languages = languages
    },
    SET_specialities(state, specialities) {
        state.specialities = specialities
    },
    UPDATE_Doctor(state, Doctor) {
        const DoctorIndex = state.doctors.findIndex((p) => p.ID == Doctor.ID)
        Object.assign(state.doctors[DoctorIndex], Doctor)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.doctors.findIndex((p) => p.ID == itemId)
        state.doctors.splice(ItemIndex, 1)
    },
    SET_DoctorSearchObj(state, DoctorSearchObj) {
        debugger
        state.DoctorSearchObj = DoctorSearchObj;
    }
}
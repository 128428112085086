/*=========================================================================================
  File Name: moduleDoctorActions.js
  Description: Doctor Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
    AddDoctor(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Doctor/AddDoctor", item)
                .then(response => {
                    // commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchDoctorsPaging({ commit }, search) {
        debugger 
        return new Promise((resolve, reject) => {
            axios.post("api/Doctor/SearchDoctors", search)
                .then((response) => {
                    var obj = {};
                    obj.doctors = response.data.Data;
                    obj.PageNumber = search.PageNumber;
                    debugger;
                    commit('SET_SearchDoctorPaging', obj)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SaveDoctorSessionPrice(context, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/DoctorSessionPrice/UpdateDoctorSessionPrice", item)
                .then((response) => {
                    // commit('UPDATE_Doctor', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SearchDoctorMonthSessions(context, search) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/DoctorSession/SearchDoctorMonthSessions", search)
                .then(response => {
                    
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchDoctorSessions(context, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/DoctorSession/SearchDoctorSessions", search)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SimpleSearchDoctorSessions(context, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/DoctorSession/SimpleSearchDoctorSessions", search)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SimpleSearchDoctors({ commit }, search) {
        
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/SimpleSearchDoctors", search)
                .then(response => {
                    commit("SET_Doctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
  
    SearchHospitalDoctors(context, search) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/Hospital/SearchHospitalDoctors" , search)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    ConfirmUser(context, userId) {
        return new Promise((resolve, reject) => {
            axios
                .post("/ConfirmUser?userId=" + userId)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SaveDoctorSession(context, model) {
        return new Promise((resolve, reject) => {
            axios.post("API/DoctorSession/SaveDoctorSessions", model)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    BlockAvailability(context, model) {
        return new Promise((resolve, reject) => {
            axios.post("API/DoctorSession/BlockDoctorSessions", model)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },


    EditAvailability(context, model) {
        return new Promise((resolve, reject) => {
            axios.post("API/DoctorSession/EditDoctorSessions", model)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    PreviewOnCalendar(context, model) {
        return new Promise((resolve, reject) => {
            axios.post("API/DoctorSession/AddDoctorSessions", model)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SearchDoctors({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/Doctor/SearchDoctors", search)
                .then((response) => {
                    commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    AddPatientReservationSessionSymptomNote(context, model) {

        return new Promise((resolve, reject) => {
            axios.post("api/PatientReservationSession/AddPatientReservationSessionSymptomNote?sessionID=" + model.ID + "&note=" + model.SymptomNote)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    AddPatientReservationSession(context, model) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("api/PatientReservationSession/AddPatientReservationSession", model)
                .then((response) => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetDoctorSessionPriceByDoctorID(context, itemid) {
        return new Promise((resolve, reject) => {
            axios.get("api/DoctorSessionPrice/GetDoctorSessionPriceByDoctorID?DoctorID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetAllDoctors({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Doctor/SearchDoctors", search)
                .then(response => {
                    commit("SET_Doctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllDoctorsList({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/GetAllDoctors")
                .then(response => {
                    commit("SET_Doctor", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateDoctor(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Doctor/UpdateDoctor", item)
                .then(response => {
                    // commit('UPDATE_Doctor', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetDoctor(context, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/GetDoctorByID?doctorID=" + itemid)
                .then(response => {
                    // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    DeleteDoctor({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .delete("api/Doctor/DeleteDoctor?doctorID=" + item.ID)
                .then(response => {
                    commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeEducation(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Doctor/DeleteEducationByID?doctorEducationID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeExperience(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/Doctor/DeleteExperienceByDoctorID?doctorExperienceID=" + item.ID
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeLink(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/DeleteLinkByDoctorID?doctorLinkID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeSessionPrice(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Doctor/DeleteSessionPriceByDoctorID?doctorLinkID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UploadImage(context, image) {
        const formData = new FormData();
        formData.append("file",image, image.name);
        return new Promise((resolve, reject) => {
          axios
            .post("/API/Common/UploadFile", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(response => {
                debugger;
              if (response.status == 200) {
                resolve(response);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },

    
};
<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div class="vx-col  w-full mt-5">
        <vx-card class="mb-base">
          <vs-table
            ref="table"
            pagination
            :max-items="5"
            :data="UserModel.PatientReservationSessionFollows"
          >
            <template slot="thead">
              <vs-th>{{ $t("Patient Note") }} </vs-th>
              <vs-th> {{ $t("Doctor Note") }}</vs-th>
              <vs-th> {{ $t("Date") }}</vs-th>
              <!-- <vs-th>{{ $t("Action") }}</vs-th> -->
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      {{ tr.PatientNote }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      {{ tr.DoctorNote }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      {{ tr.Date | formatDate() }}
                    </p>
                  </vs-td>
                  <!-- <vs-td class="whitespace-no-wrap">
                    <div class="vx-row">
                      <vx-tooltip :text="$t('Edit')" position="top">
                        <feather-icon
                          icon="EditIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="editModule(tr)"
                        />
                      </vx-tooltip>
                      <vx-tooltip :text="$t('Material')" position="top">
                        <feather-icon
                          icon="InfoIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          class="ml-2"
                          @click="viewMaterial(tr)"
                        />
                      </vx-tooltip>


                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="m-2 "
                        @click="openConfirm(tr)"
                      />
                    </div>
                  </vs-td> -->
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      UserModel: this.data
    };
  },
  computed: {},
  methods: {},
  created() {}
};
</script>

<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">
      <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click=" $router.go(-1)"
    />
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Users</router-link
        >
      </span>
    </vs-alert>

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs
          class="tab-action-btn-fill-conatiner"
          style="margin-bottom: 220px"
        >
          <vs-tab
            :label="$t('Main Information')"
            icon-pack="feather"
            icon="icon-info"
          >
            <div class="tab-text">
              <add-edit-main-add class="mt-4" :UserModel="Model" v-if="$route.params.ID==0" />
              <add-edit-main class="mt-4" :UserModel="Model" v-else />

            </div>
          </vs-tab>

          <!-- <vs-tab
            :label="$t('Session Follows')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-sessionFollows class="mt-4" :data="Model" />
            </div>
          </vs-tab> -->

          <vs-tab
            :label="$t('Medical Report Requests')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-medicalReportRequests class="mt-4" :data="Model" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Medical History Scans')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-MedicalHistoryScans class="mt-4" :data="Model" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Medical History Reports')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-medicalHistoryReports class="mt-4" :data="Model" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Medical History Analysis')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-medicalHistoryAnalysis class="mt-4" :data="Model" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Session Comments')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <SessionComments class="mt-4" :data="Model" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Session Prescriptions')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <PatientReservationPrescriptions
                class="mt-4"
                :UserModel="Model"
              />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Session Diagnosis')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <PatientReservationDiagnosis class="mt-4" :UserModel="Model" />
            </div>
          </vs-tab>

          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  class="ml-4 mt-2"
                  @click="save_changes"
                  :disabled="!isFormValid"
                >
                  {{ $t("Save") }}
                </vs-button>
                <vs-button
                  class="ml-4 mt-2"
                  type="border"
                  color="danger"
                  @click="Back()"
                >
                  {{ $t("Back ") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import AddEditMedicalReportRequests from "./Add-Edit-MedicalReportRequests.vue";
import AddEditMedicalHistoryScans from "./Add-Edit-MedicalHistoryScans.vue";
import AddEditMedicalHistoryReports from "./Add-Edit-MedicalHistoryReports.vue";
import AddEditMain from "./Add-Edit-Main.vue";
import AddEditMainAdd from "./Add-Edit-Main-Add.vue";
import AddEditSessionFollows from "./Add-Edit-SessionFollows.vue";
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";
import AddEditMedicalHistoryAnalysis from "./Add-Edit-MedicalHistoryAnalysis.vue";
import SessionComments from "./SessionComments.vue";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
import modulePaymentType from "@/store/settings/paymentType/modulePaymentType.js";

import PatientReservationDiagnosis from "./Add-Edit-PatientReservationDiagnosis.vue";
import PatientReservationPrescriptions from "./Add-Edit-PatientReservationPrescriptions.vue";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";

export default {
  components: {
    AddEditMedicalHistoryReports,
    AddEditMedicalHistoryScans,
    AddEditSessionFollows,
    AddEditMedicalReportRequests,
    AddEditMain,
    AddEditMainAdd,
    SessionComments,
    AddEditMedicalHistoryAnalysis,
    PatientReservationDiagnosis,
    PatientReservationPrescriptions,
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.Model.StatusID != null&& this.Model.isFormValid;
    },
    
  },

  data() {
    return {
      Model: {
        StatusID:null,
        PaymentStatusID:null,
        UserPatientLocations: [],
        UserSpecialties: [],
        UserHospitalLocations: [],
        UserHospitals: [],
        PatientMedicalHistoryScans: [],
        PatientMedicalHistoryReports: [],
        PatientMedicalHistoryAnalysis: [],
        PatientMedicalReportRequests:[],
        PatientReservationSessionDiagnosis:[],
        PatientReservationSessionPrescriptions:[
        // PatientReservatientSessionMedicals=[]
         ],
        PatientReservationSessionFollows:[],
        PatientReservationSessionComments:[],
        isFormValid:true,
        Doctor:{},
        Patient:{},
        DoctorSession:{
          DateFrom: "",
          DateTo: "",

        },
      },
      user_not_found: false,
      activeTab: 0,
    };
  },
  watch: {},
  methods: {
    reset_data() {
      this.initValues();
    },
    Back() {
      //a7ot el parameter
      this.$router.go(-1);
    },
    save_changes() {
      if(this.Model.ID==undefined||this.Model.ID==0||this.Model.ID==null){
        this.Model.DoctorID = this.Model.Doctor.ID;
        this.Model.PatientID = this.Model.Patient.ID;
        this.Model.DoctorSessionID = this.Model.DoctorSession.ID;
        this.Model.Doctor = null;
        this.Model.Patient = null;
        this.Model.DoctorSession = null;
        this.Model.ByAgent = true;
        
        this.$store
        .dispatch("ReservationList/AddPatientReservationSession", this.Model)
        .then(() => {
          window.showAddSuccess();
          this.initValues();
          this.Back();
          this.$vs.loading.close();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            window.showAddFailed(err.response.data.message);
          } else {
            window.showAddFailed("");
          }
          //console.error(err);
          this.$vs.loading.close();
        });
      }
      else{
        let m = {};
      m.PatientReservationSessionID = this.Model.ID;
      m.PatientID = this.Model.PatientID;
      m.DoctorSessionID = this.Model.DoctorSessionID;
      m.DoctorID = this.Model.DoctorID;
      m.StatusID = this.Model.StatusID;
      m.PaymentStatusID = this.Model.PaymentStatusID;
      debugger;
      if (this.Model.CanFillSymptomNote) {
        this.$store
          .dispatch(
            "ReservationList/AddPatientReservationSessionSymptomNote",
            this.Model
          )
          .then(() => {
            this.$vs.loading.close();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showAddFailed(err.response.data.message);
            } else {
              window.showAddFailed("");
            }
            //console.error(err);
            this.$vs.loading.close();
          });
      }
      this.$store
        .dispatch("ReservationList/ChangePatientReservationSessionStatus", m)
        .then(() => {
          window.showAddSuccess();
          this.initValues();
          this.Back();
          this.$vs.loading.close();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            window.showAddFailed(err.response.data.message);
          } else {
            window.showAddFailed("");
          }
          //console.error(err);
          this.$vs.loading.close();
        });
      }
     
    },
    initValues() {
      this.Model = {
        UserPatientLocations: [],
        UserSpecialties: [],
        UserHospitalLocations: [],
        UserHospitals: [],
        Doctor:{},
        Patient:{},
        StatusID:null,
        PaymentStatusID:null,
        DoctorSession:{
          DateFrom: "",
          DateTo: ""
        },
      };
    },
  },
  created() {
    if (!modulePaymentType.isRegistered) {
      this.$store.registerModule("PaymentTypeList", modulePaymentType);
      modulePaymentType.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.$store.dispatch("DoctorList/SimpleSearchDoctors", {});

    this.$store.dispatch("PaymentTypeList/GetAllPaymentTypes");
    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");

    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    const PatientID = this.$route.params.PatientID;

    if (ID != undefined&&ID!=0) {
      this.$vs.loading();
      this.$store
        .dispatch("ReservationList/GetPatientReservationSession", ID)
        .then((res) => {
          debugger
          this.Model = res.data.Data;
          if(this.Model.PatientMedicalHistoryAnalysis==null||this.Model.PatientMedicalHistoryAnalysis==undefined){
            this.Model.PatientMedicalHistoryAnalysis = [];
          }
           if(this.Model.PatientMedicalHistoryReports==null||this.Model.PatientMedicalHistoryReports==undefined){
            this.Model.PatientMedicalHistoryReports = [];
          }
           if(this.Model.PatientMedicalHistoryScans==null||this.Model.PatientMedicalHistoryScans==undefined){
            this.Model.PatientMedicalHistoryScans = [];
          }
           if(this.Model.PatientMedicalReportRequests==null||this.Model.PatientMedicalReportRequests==undefined){
            this.Model.PatientMedicalReportRequests = [];
          }
           if(this.Model.PatientReservationSessionDiagnosis==null||this.Model.PatientReservationSessionDiagnosis==undefined){
            this.Model.PatientReservationSessionDiagnosis = [];
          }
           if(this.Model.PatientReservationSessionPrescriptions==null||this.Model.PatientReservationSessionPrescriptions==undefined){
            this.Model.PatientReservationSessionPrescriptions = [];
          }
           if(this.Model.PatientReservationSessionFollows==null||this.Model.PatientReservationSessionFollows==undefined){
            this.Model.PatientReservationSessionFollows = [];
          }
          if(this.Model.PatientReservationSessionComments==null||this.Model.PatientReservationSessionComments==undefined){
            this.Model.PatientReservationSessionComments = [];
          }
          this.$set(
            this.Model,
            "MainPaymentStatusID",
            this.Model.PaymentStatusID
          );
             this.$set(
            this.Model,
            "isFormValid",
            true
          );
          this.$set(this.Model, "MainStatusID", this.Model.StatusID);
          if (
            this.Model.SymptomNote == null ||
            this.Model.SymptomNote == undefined ||
            this.Model.SymptomNote == ""
          ) {
            this.$set(this.Model, "CanFillSymptomNote", true);
          } else {
            this.$set(this.Model, "CanFillSymptomNote", false);
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
          //console.error(err)
        });
    }
    if (!modulePatient.isRegistered) {
       this.$store.registerModule("patientList", modulePatient);
       modulePatient.isRegistered = true;
     }
    if(PatientID!=undefined && PatientID !=0){
     
    
    }
    var dayNow = new Date();
    var myDate = new Date(this.editSessionModel.DoctorSession.DayUTC);
    dayNow = new Date(dayNow.getTime() + 3 * 24 * 60 * 60 * 1000);
    var date1 = dayNow.getTime();
    var date2 = myDate.getTime();
    if (date1 > date2) {
      this.isFormValid = false;
    }
  },
};
</script>

/*=========================================================================================
  File Name: moduleDoctor.js
  Description: Doctor Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleDoctorState.js'
import mutations from './moduleDoctorMutations.js'
import actions from './moduleDoctorActions.js'
import getters from './moduleDoctorGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

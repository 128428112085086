/*=========================================================================================
  File Name: modulePaymentStatusMutations.js
  Description: PaymentStatus Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.PaymentStatus.unshift(item)
  },
  SET_PaymentStatus(state, PaymentStatus) {
    state.PaymentStatus = PaymentStatus
  },
  UPDATE_PaymentStatus(state, PaymentStatus) {
    const PaymentStatusIndex = state.PaymentStatus.findIndex((p) => p.ID == PaymentStatus.ID)
    Object.assign(state.PaymentStatus[PaymentStatusIndex], PaymentStatus)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.PaymentStatus.findIndex((p) => p.ID == itemId)
    state.PaymentStatus.splice(ItemIndex, 1)
},
}

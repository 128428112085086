/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    doctors: [],
    languages: [],
    specialities: [],
    searchDoctors: [],
    DoctorSearchObj: {
        AppointmentDateFrom: '',
        AppointmentDateTo: '',
        SurgeryDateFrom: '',
        SurgeryDateTo: '',
        PageNumber: '',
        AssignedAccountManagerID: null,
        HospitalID: '',
        DoctorName: null,
        SpecialtyIDs: '',
        languageIDs: '',
        CountryID: '',
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20
    }
}

/*=========================================================================================
  File Name: modulePatientMutations.js
  Description: Patient Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_ITEM(state, item) {
        state.patients.unshift(item)
    },
    SET_Patient(state, patients) {
        state.patients = patients
    },
    SET_PatientPaging(state, obj) { 
        debugger
        if (obj.PageNumber == 1) {
            
            state.patients = obj.Patients
        } else {
            if (obj.Patients.length > 0) {
                obj.Patients.forEach(element => {
                    state.patients.push(element)
                });
            }
            debugger
        }
    },
    SET_PatientMedicalHistoryReports(state, PatientMedicalHistoryReports) {
        state.PatientMedicalHistoryReports = PatientMedicalHistoryReports
    },
    SET_PatientMedicalHistoryScans(state, PatientMedicalHistoryScans) {
        state.PatientMedicalHistoryScans = PatientMedicalHistoryScans
    },
    SET_PatientMedicalHistoryAnalysis(state, PatientMedicalHistoryAnalysis) {
        state.PatientMedicalHistoryAnalysis = PatientMedicalHistoryAnalysis
    },
    SET_PendingMedicalDocumentations(state, PendingMedicalDocumentations) {
        state.PendingMedicalDocumentations = PendingMedicalDocumentations
    },
    SET_PatientReservationSurgeries(state, PatientReservationSurgeries) {
        state.PatientReservationSurgeries = PatientReservationSurgeries
    },
    SET_ReservedMedicalPackage(state, ReservationPackages) {
        state.ReservationPackages = ReservationPackages
    },
    SET_ProfitData(state, ProfitData) {
        state.ProfitData = ProfitData
    },
    UPDATE_Patient(state, Patient) {
        const PatientIndex = state.patients.findIndex((p) => p.ID == Patient.ID)
        Object.assign(state.patients[PatientIndex], Patient)
    },
    delete_PendingMedicalDocumentations(state, request) {
        const Index = state.PendingMedicalDocumentations.findIndex((p) => p.ID == request.ID)
        state.PendingMedicalDocumentations.splice(Index, 1)
    },
    REMOVE_ITEM(state, itemId) {
        debugger
        const ItemIndex = state.patients.findIndex((p) => p.ID == itemId)
        state.patients.splice(ItemIndex, 1)
    },
    SET_PatientSearchObj(state, PatientSearchObj) {
        debugger
        state.PatientSearchObj = PatientSearchObj;
    }
}
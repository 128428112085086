/*=========================================================================================
  File Name: modulePatientActions.js
  Description: Patient Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
    AddPatientReservationSessionComment({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("Api/PatientReservationSessionComment/AddPatientReservationSessionComment", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    EquationReservedSession({ commit }, ReferenceNumber) {
        return new Promise((resolve, reject) => {
            axios.post("/API/PatientReservationSession/EquationReservedSession?ReferenceNumber=" + ReferenceNumber)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SettleSurgery({ commit }, ReferenceNumber) {
        return new Promise((resolve, reject) => {
            axios.post("/API/PatientReservationSurgery/SettlePatientReservationSurgery?ReferenceNumber=" + ReferenceNumber)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SettleReservedMedicalPackage({ commit }, ReferenceNumber) {
        return new Promise((resolve, reject) => {
            axios.post("/API/ReservedMedicalPackage/SettleReservedMedicalPackage?ReferenceNumber=" + ReferenceNumber)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    SearchPatientReservationSessions({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/SearchPatientReservationSessions",
                    item
                )
                .then(response => {
                    commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // UpdatePatient({ commit }, item) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post("/api/Patient/UpdatePatient", item)
    //       .then(response => {
    //         commit("UPDATE_Patient", item);
    //         resolve(response);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },

    GetPatient(Context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Patient/GetAllPatients")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetAllStatuses(Context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Status/GetAllStatuses")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllPaymentStatuses(Context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/PaymentStatus/GetAllPaymentStatuses")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReservationSession(context, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSession/GetPatientReservationSession?patientReservationID=" +
                    id
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReservationByReferenceNumber(context, ReferenceNumber) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSession/GetPatientReservationByReferenceNumber?ReferenceNumber=" +
                    ReferenceNumber
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeletePatientMedicalHistoryReport(context, ID) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientMedicalHistoryReport/DeletePatientMedicalHistoryReport?ID=" +
                    ID
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeletePatientMedicalHistoryAnalysis(context, ID) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientMedicalHistoryAnalysis/DeletePatientMedicalHistoryAnalysis?ID=" +
                    ID
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeletePatientMedicalHistoryScan(context, ID) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientMedicalHistoryScan/DeletePatientMedicalHistoryScan?ID=" +
                    ID
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientReservationSession(context, data) {
        
        return new Promise((resolve, reject) => {
            axios
                .post("api/PatientReservationSession/AddPatientReservationSession", data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientReservationSessionSymptomNote(context, model) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "api/PatientReservationSession/AddPatientReservationSessionSymptomNote?sessionID=" +
                    model.ID +
                    "&note=" +
                    model.SymptomNote
                )
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddReport(context, model) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "api/PatientMedicalHistoryReport/AddPatientMedicalHistoryReport", model)
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddScan(context, model) {
        debugger

        return new Promise((resolve, reject) => {
            axios
                .post(
                    "api/PatientMedicalHistoryScan/AddPatientMedicalHistoryScan", model)
                .then(response => {
                    debugger
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddAnalysis(context, model) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "api/PatientMedicalHistoryAnalysis/AddPatientMedicalHistoryAnalysis", model)
                .then(response => {
                    // commit('SET_SearchDoctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    ChangePatientReservationSessionStatus(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/ChangePatientReservationSessionStatus",
                    item
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AdminChangeStatusPatientReservationSession(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/AdminChangeStatusPatientReservationSession",
                    item
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};
/*=========================================================================================
  File Name: modulePaymentTypeMutations.js
  Description: PaymentType Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.PaymentTypes.unshift(item)
  },
  SET_PaymentType(state, PaymentTypes) {
    state.PaymentTypes = PaymentTypes
  },
  UPDATE_PaymentType(state, PaymentType) {
    const PaymentTypeIndex = state.PaymentTypes.findIndex((p) => p.ID == PaymentType.ID)
    Object.assign(state.PaymentTypes[PaymentTypeIndex], PaymentType)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.PaymentTypes.findIndex((p) => p.ID == itemId)
    state.PaymentTypes.splice(ItemIndex, 1)
},
}

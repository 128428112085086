<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div>
      <div class="vx-row">
        <div class="vx-col lg:w-4/4 w-full">
          <vx-card class="mb-base">
            <!-- <vs-button
              type="border"
              class="mb-4 mt-4"
              icon-pack="feather"
              icon="icon-plus"
              @click="activePrompt = true"
              >{{ $t("AddSpecialty") }}
            </vs-button> -->

            <vs-table
              ref="table"
              pagination
              :max-items="5"
              :data="UserModel.PatientMedicalReportRequests"
            >
              <template slot="thead">
                <vs-th>{{ $t("Request Type") }}</vs-th>
                <vs-th>{{ $t("Status") }}</vs-th>

                <vs-th>{{ $t("Date") }}</vs-th>
                <!-- <vs-th>{{ $t("Action") }}</vs-th> -->
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td class="product-name font-medium truncate">
                      <p v-if="tr.DoctorRequestType">
                        {{ tr.DoctorRequestType.Name }}
                      </p>
                    </vs-td>
                    <vs-td class="product-name font-medium truncate">
                      <p v-if="tr.Staus">
                        {{ tr.Staus.Name }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.Date | formatDate() }}
                      </p>
                    </vs-td>

                    <!-- <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="m-2 "
                        @click="openConfirm(tr)"
                      />
                    </vs-td> -->
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      UserModel: this.data
    };
  },
  computed: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

/*=========================================================================================
  File Name: moduleStatusMutations.js
  Description: Status Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.status.unshift(item)
  },
  SET_Status(state, status) {
    state.status = status
  },
  UPDATE_Status(state, Status) {
    const StatusIndex = state.status.findIndex((p) => p.ID == Status.ID)
    Object.assign(state.status[StatusIndex], Status)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.status.findIndex((p) => p.ID == itemId)
    state.status.splice(ItemIndex, 1)
},
}

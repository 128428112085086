<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Reference Number") }}</label>
          <vs-input
            disabled
            v-model="UserModel.ReferenceNumber"
            class="w-full"
            name="Reference Number"
            :data-vv-as="$t('Reference Number')"
          />
          <span class="text-danger text-sm" v-show="errors.has('Reference Number')">{{
            errors.first("Reference Number")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Booking Date") }}</label>
          <datepicker
            disabled
            v-model="UserModel.CreatedDate"
            class="vx-col w-full"
            name="Date"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Doctor") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="UserModel.Doctor"
            :options="doctors"
            :reduce="(ID) => ID"
            @input="GetDoctorSession(UserModel.Doctor.ID)"
          />
        </div>
        
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Appointment Date") }}</label>
          <v-select
            label="DateFrom"
            class="w-full"
            v-model="UserModel.DoctorSession"
            :options="DoctorSessions"
            :reduce="(ID) => ID"
          />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Time From") }}</label>
          <vs-input disabled v-model="TimeFrom" class="vx-col w-full" type="time" />
          <span class="text-danger text-sm" v-show="errors.has('Time From')">{{
            errors.first("Time From")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Time To") }}</label>
          <vs-input disabled v-model="TimeTo" class="vx-col w-full" type="time" />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Doctor Name") }}</label>

          <vs-input
            disabled
            v-model="UserModel.Doctor.Name"
            class="w-full"
            name="DoctorName"
            :data-vv-as="$t('DoctorName')"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Doctor Name')">{{
            errors.first("Doctor Name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Doctor Email") }}</label>
          <vs-input
            disabled
            v-model="UserModel.Doctor.Email"
            class="w-full"
            :data-vv-as="$t('Doctor Email')"
            name="DoctorEmail"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Doctor Email')">{{
            errors.first("Doctor Email")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Doctor Phone") }}</label>
          <vs-input
            disabled
            v-model="UserModel.Doctor.Phone"
            class="w-full"
            name="DoctorPhone"
            v-validate="'required'"
          />
          <span
            class="text-danger md:w-1/3 text-sm"
            v-show="errors.has('Doctor Phone')"
            >{{ errors.first("Doctor Phone") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Doctor Specialty") }}</label>
          <vs-input
            disabled
            v-model="UserModel.Doctor.SpecialtiesEN"
            class="w-full"
            name="DoctorName"
            :data-vv-as="$t('DoctorName')"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Doctor Name')">{{
            errors.first("Doctor Name")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Name") }}</label>
          <vs-input
            disabled
            v-model="UserModel.Patient.Name"
            class="w-full"
            name="Patient Name"
            :data-vv-as="$t('Patient Name')"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Patient Name')">{{
            errors.first("Patient Name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Email") }}</label>
          <vs-input
            disabled
            v-model="UserModel.Patient.Email"
            class="w-full"
            :data-vv-as="$t('Patient Email')"
            name="Patient Email"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('Patient Email')">{{
            errors.first("Patient Email")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Patient Phone") }}</label>
          <vs-input
            disabled
            v-model="UserModel.Patient.Phone"
            class="w-full"
            name="Patient Phone"
            v-validate="'required'"
          />
          <span
            class="text-danger md:w-1/3 text-sm"
            v-show="errors.has('Patient Phone')"
            >{{ errors.first("Patient Phone") }}</span
          >
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>

          <v-select
            label="Name"
            class="w-full"
            v-model="UserModel.StatusID"
            :options="ALLstatus"
            :reduce="(ID) => ID.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>

          <v-select
            label="Name"
            class="w-full"
            v-model="UserModel.PaymentStatusID"
            :options="AllPaymentStatus"
            :reduce="(ID) => ID.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>

        <div class="vx-col w-full">
          <label class="text-sm opacity-75">{{ $t("Symptom") }}</label>
          <vs-textarea
            v-model="UserModel.SymptomNote"
            class="w-full"
            name="SymptomNote"
            v-validate="'required'"
          />
          <span class="text-danger md:w-1/3 text-sm" v-show="errors.has('SymptomNote')">{{
            errors.first("SymptomNote")
          }}</span>
        </div>
        <div class="vx-col w-full">
          <label class="text-sm opacity-75">Doctor Comment</label>
          <vs-textarea
            :disabled="true"
            v-model="UserModel.DoctorComment"
            class="w-full"
            name="SymptomNote"
            v-validate="'required'"
          />
        </div>
        <div class="vx-col w-full">
          <label class="text-sm opacity-75">Diagnosis Note</label>
          <vs-textarea
            :disabled="true"
            v-model="UserModel.DiagnosisNote"
            class="w-full"
            name="SymptomNote"
            v-validate="'required'"
          />
        </div>
      </div>
    </vx-card>

    <div class="w-full mt-1">
      <vx-card class="mb-base" id="data-list-list-view">
        <h3 class="mb-4">Session Logs</h3>
        <vs-table ref="table" pagination :max-items="5" :data="UserModel.Patientlogs">
          <template slot="thead">
            <vs-th> {{ $t("Details") }}</vs-th>
            <vs-th> {{ $t("Date") }}</vs-th>
            <!-- <vs-th>{{ $t("Action") }}</vs-th> -->
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr1" :key="indexkr" v-for="(tr1, indexkr) in data">
                <vs-td>
                  <p class="product-name font-medium">
                    {{ tr1.Details }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr1.CreatedDate | formatDate() }}
                  </p>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";

import vSelect from "vue-select";
// import { domain, mainSetting } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    vSelect,
    Datepicker,
  },
  props: {
    UserModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Status: [],
      DoctorSessions:[],
      FilterdStatus: [],
      paymentStatus: [],
      // uploadUrl: domain + "API/Course/UploadFile",
      // UploadRequestHeader: mainSetting.UploadRequestHeader,
      // baseURL: domain,
    };
  },
  
  computed: {
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
   
    
  
    ALLstatus() {
      debugger;
      return this.$store.state.StatusList.status;
    },
    TimeTo() {
      return this.UserModel.DoctorSession.DateTo.slice(11, 16);
    },
    TimeFrom() {
      return this.UserModel.DoctorSession.DateFrom.slice(11, 16);
    },
    Editable() {
      return this.UserModel.MainStatusID != 5 && this.UserModel.MainStatusID != 3;
    },
    AllPaymentStatus() {
      debugger;
      return this.$store.state.PaymentStatusList.PaymentStatus;
    },
    PaymentTypes() {
      return this.$store.state.PaymentTypeList.PaymentTypes;
    },
  },
  methods: {
    GetDoctorSession(doctorID){
      this.UserModel.DoctorSession = {}
      this.UserModel.DoctorSession.DateFrom = '';
      this.UserModel.DoctorSession.DateTo = '';

     if(doctorID>0){
      var model = {};
      model.DoctorID = doctorID;
      model.DateFrom =new Date();
      model.DateFrom.setDate(model.DateFrom.getDate() + 2);  //3lshan fl api buna2s youm
      model.DateFrom.setHours(0,0,0)
      model.IsAvailable = true;
      this.$store
        .dispatch("DoctorList/SimpleSearchDoctorSessions", model).then(res=>{
          debugger
          if(res.data==undefined)
          this.DoctorSessions = res[0].data.Data;
          else
          this.DoctorSessions = res.data.Data;

        })
     }
    },
  },
  created() {
    if (!moduleStatus.isRegistered) {
      this.$store.registerModule("StatusList", moduleStatus);
      moduleStatus.isRegistered = true;
    }
    this.$store
       .dispatch("patientList/GetPatient", this.$route.params.PatientID)
       .then((res) => {
         debugger;
         this.UserModel.Patient= res.data.Data;
         debugger;
       })
       .catch((err) => {
         debugger;
         this.$vs.loading.close();
       });
    this.$store.dispatch("StatusList/GetAllStatus");
    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");

    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    debugger;
    this.UserModel;
     this.UserModel.CreatedDate = new Date();
     this.UserModel.StatusID = 1;

     this.UserModel.PaymentStatusID = 1;
   
    debugger;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

/*=========================================================================================
  File Name: moduleLanguage.js
  Description: Language Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleScanTypeState.js'
import mutations from './moduleScanTypeMutations.js'
import actions from './moduleScanTypeActions.js'
import getters from './moduleScanTypeGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
